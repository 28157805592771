import React, { useState } from 'react';
import { useStore } from 'effector-react';
import {
    getMonthReport,
    getReportGraphForYear,
    getAllMonthSprints,
    getReportGraphForLastYear,
    getManuallyAchievements,
} from '../components/api';
import {
    $monthReport,
    $filteredSprints,
    $users,
    $authUserData,
    setMonthReport
} from '../store/cards';
import { MonthReportRow, GraphSettingsForm } from '../components';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Select,
    MenuItem,
    FormHelperText,
    Button,
    IconButton
} from '@mui/material';
import {
    ArrowLeft,
    ArrowRight,
} from '@mui/icons-material';
import { LineChart, ChartsReferenceLine } from '@mui/x-charts';
import '../styles/month-report.scss';
import Cookies from 'universal-cookie';

const REST_URL = process.env.REACT_APP_REST_URL;

const months = [
    new Date(1990, 0, 1),
    new Date(1990, 1, 1),
    new Date(1990, 2, 1),
    new Date(1990, 3, 1),
    new Date(1990, 4, 1),
    new Date(1990, 5, 1),
    new Date(1990, 6, 1),
    new Date(1990, 7, 1),
    new Date(1990, 8, 1),
    new Date(1990, 9, 1),
    new Date(1990, 10, 1),
    new Date(1990, 11, 1),
];

let date = new Date();
date = new Date(date.setDate(1));
date = new Date(date.setMonth(date.getMonth() + 1))

let monthsForLastYear = [];

for (let i = 0; i < 12; i++) {
    monthsForLastYear.unshift(date);
    date = new Date(date.setMonth(date.getMonth() - 1));
}

const defaultGraphForYear = {
    tasks_sp_count: [0],
    tasks_count: [0],
    review_tasks_sp_count: [0],
    support_tasks_sp_count: [0],
    rules_tasks_sp_count: [0],
    development_ts_tasks_sp_count: [0],
    total_sp_count: [0],
    weeks: [0]
}

const defaultGraphSettings = {
    showLines: true, //Показывать горизонтальные линии
    smoothingLines: 'linear', //Сглаживание линий (linear, catmullRom)
    showSP: true,
    showCount: true,
    showTasks: true,
    showReviewTasks: true,
    showSupportTasks: true,
    showRulesTasks: true,
    showDevelopmentTSTasks: true,
    reportGraphUser: '',
    reportGraphYear: 0,
    resultsGraph: 0
}

export function MonthReportPage() {
    const [graphForYear, setGraphForYear] = useState(defaultGraphForYear);
    const [showSP, setShowSP] = useState(true);
    const [showCount, setShowCount] = useState(true);
    const [showTasks, setShowTasks] = useState(true);
    const [showReviewTasks, setShowReviewTasks] = useState(true);
    const [showSupportTasks, setShowSupportTasks] = useState(true);
    const [showRulesTasks, setShowRulesTasks] = useState(true);
    const [showDevelopmentTSTasks, setShowDevelopmentTSTasks] = useState(true);
    const [reportGraphUser, setReportGraphUser] = useState('');
    const [reportGraphYear, setReportGraphYear] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const monthReport = useStore($monthReport);
    const filteredSprints = useStore($filteredSprints);
    const users = useStore($users);
    const authUserData = useStore($authUserData);
    const [selectedSprint, setSelectedSprint] = useState(`${new Date().getMonth() + 1};${new Date().getFullYear()}`);
    const [sprints, setSprints] = useState([]);
    const [graphSettings, setGraphSetting] = useState(defaultGraphSettings);
    const [showGraphSettingsForm, setShowGraphSettingsForm] = useState(false);
    const [manuallyAchievements, setManuallyAchievements] = useState([]);
    const [resultsGraph, setResultsGraph] = useState(0);

    React.useEffect(() => {
        document.title = "TaskMan - Месячный отчёт";
    });

    const showedSPTasks = graphForYear.tasks_sp_count.map((sp, index) => {
        const tasksSpCount = showTasks ? sp : 0;
        const reviewSPTasks = showReviewTasks ? graphForYear.review_tasks_sp_count[index] : 0;
        const supportSPTasks = showSupportTasks ? graphForYear.support_tasks_sp_count[index] : 0;
        const rulesSPTasks = showRulesTasks ? graphForYear.rules_tasks_sp_count[index] : 0;
        const developmentTsSPTasks = showDevelopmentTSTasks ? graphForYear.development_ts_tasks_sp_count[index] : 0;
        return tasksSpCount + reviewSPTasks + supportSPTasks + rulesSPTasks + developmentTsSPTasks;
    });

    const resultSpForTable =
        + (showTasks ? monthReport.result_tasks_sp_count : 0)
        + (showReviewTasks ? monthReport.result_review_sp_count : 0)
        + (showSupportTasks ? monthReport.result_support_tasks_sp_count : 0)
        + (showRulesTasks ? monthReport.result_rules_tasks_sp_count : 0)
        + (showDevelopmentTSTasks ? monthReport.result_development_ts_tasks_sp_count : 0);


    React.useEffect(() => {
        getAllMonthSprints().then(data => setSprints(data));
        if (sprints.length) {
            setSelectedSprint(`${sprints[0].month};${sprints[0].year}`);
        }
    }, [sprints.length]);

    React.useEffect(() => {
        updateMonthReport();
    }, [isLoaded, selectedSprint]);

    React.useEffect(() => {
        if (isLoaded) {
            reportGraphYear !== 0
                ? getReportGraphForYear(reportGraphYear, reportGraphUser).then(data => setGraphForYear(data))
                : getReportGraphForLastYear(reportGraphUser).then(data => {
                    setGraphForYear(data);
                    monthsForLastYear = data.dates.map(date => new Date(date));
                });
        }
    }, [isLoaded, reportGraphUser, reportGraphYear]);

    const initGraphics = (settings) => {
        setReportGraphUser(settings.reportGraphUser);
        setReportGraphYear(settings.reportGraphYear)
        setShowSP(settings.showSP);
        setShowCount(settings.showCount);
        setShowTasks(settings.showTasks);
        setShowReviewTasks(settings.showReviewTasks);
        setShowSupportTasks(settings.showSupportTasks);
        setShowRulesTasks(settings.showRulesTasks);
        setShowDevelopmentTSTasks(settings.showDevelopmentTSTasks)
        setResultsGraph(settings.resultsGraph);
    }

    const getGraphYHeight = () => {
        if((reportGraphUser === '' && Number(resultsGraph) === 1) 
            || (reportGraphUser !== '' && Number(resultsGraph) === 0))
            return [10, 20, 30, 40, 50, 60]
        else if (reportGraphUser !== '' && Number(resultsGraph) === 1)
            return [2, 4, 6, 8, 10]
        return [50, 100, 150, 200, 250];
    }

    const graphLines = getGraphYHeight();
    const maxGraphYHeight = graphLines[graphLines.length - 1];

    const getSprintData = () => {
        const sprintData = selectedSprint.target ? selectedSprint.target.value : selectedSprint;
        const [month, year] = sprintData.split(';');
        return {
            month: month,
            year: year
        }
    }

    const formattedData = (month, year) => {
        var options = {
            year: 'numeric',
            month: 'long',
            timezone: 'UTC'
        };
        const date = new Date(`${year}-${month}-01`);
        return `${date.toLocaleString("ru", options)}`
    };

    const changeSelectedSprint = (event) => {
        setSelectedSprint(event.target.value);
    }

    const updateMonthReport = () => {
        const { month, year } = getSprintData();
        getMonthReport(year, month).then(monthReport => {
            const cookies = new Cookies();
            const graphSettingsData = cookies.get("graphSettings") ?? defaultGraphSettings;
            setGraphSetting(graphSettingsData);
            initGraphics(graphSettingsData ?? defaultGraphSettings);
            setMonthReport(monthReport);
            getManuallyAchievements().then(data => setManuallyAchievements(data));
            setIsLoaded(true);
        });
    }

    const getReportDownloadUrl = () => {
        const { month, year } = getSprintData();
        return `${REST_URL}/monthReportExcel/${year}/${month}`;
    }

    const previousMonth = () => {
        const sprintData = getSprintData();
        const date = new Date(`${sprintData.year}-${sprintData.month}-01`);
        date.setMonth(date.getMonth() - 1);
        setSelectedSprint(`${date.getMonth() + 1};${date.getFullYear()}`)
    }

    const nextMonth = () => {
        const sprintData = getSprintData();
        const date = new Date(`${sprintData.year}-${sprintData.month}-01`);
        date.setMonth(date.getMonth() + 1);
        setSelectedSprint(`${date.getMonth() + 1};${date.getFullYear()}`)
    }

    const getSprintMonthYearToSelect = (sprint) => {
        return sprint ? `${sprint.month};${sprint.year}` : `${new Date().getMonth() + 1};${new Date().getFullYear()}`;
    }

    React.useEffect(() => {
        if (isLoaded) {
            graphSettings.showSP = showSP;
            graphSettings.showCount = showCount;
            graphSettings.showTasks = showTasks;
            graphSettings.showReviewTasks = showReviewTasks;
            graphSettings.showSupportTasks = showSupportTasks;
            graphSettings.showRulesTasks = showRulesTasks;
            graphSettings.showDevelopmentTSTasks = showDevelopmentTSTasks;
            graphSettings.reportGraphUser = reportGraphUser;
            graphSettings.reportGraphYear = reportGraphYear;
            graphSettings.resultsGraph = resultsGraph;
            updateGraphSettings(graphSettings);
        }
    }, [showSP,
        showCount,
        showTasks,
        showReviewTasks,
        showSupportTasks,
        showRulesTasks,
        showDevelopmentTSTasks,
        reportGraphUser,
        reportGraphYear,
        resultsGraph]);

    const updateGraphSettings = (value) => {
        const cookies = new Cookies();
        cookies.set("graphSettings", value);
        setGraphSetting(value);
    }

    return (
        <div className='month-report__body'>
            <div className='month-report__table-block'>
                <div className='month-report__month-controls'>
                    <IconButton
                        style={{ padding: 0 }}
                        onClick={() => previousMonth()}
                        disabled={getSprintMonthYearToSelect(sprints[sprints.length - 1]) === selectedSprint}
                    >
                        <ArrowLeft />
                    </IconButton>
                    <Select className='month-report__sprints-combobox' onChange={changeSelectedSprint} value={selectedSprint}>
                        {sprints.map((sprint) => {
                            return (<MenuItem value={`${sprint.month};${sprint.year}`} key={(sprint.month + sprint.year) * sprint.year}>{`${formattedData(sprint.month, sprint.year)}`}</MenuItem>)
                        })}
                    </Select>
                    <IconButton
                        style={{ padding: 0 }}
                        onClick={() => nextMonth()}
                        disabled={getSprintMonthYearToSelect(sprints[0]) === selectedSprint}
                    >
                        <ArrowRight />
                    </IconButton>
                    {authUserData && authUserData.is_head_of_department
                        ? <a href={getReportDownloadUrl()}>
                            <Button
                                className='month-report__download-button'
                                variant="contained"
                            >
                                Скачать
                            </Button>
                        </a>
                        : null}
                </div>
                <div className='month-report__table'>
                    <TableContainer component={Paper} width={700}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <TableRow className='month-report__table__results'>
                                    <TableCell width={50}></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell width={60} align="right"><b>SP</b></TableCell>
                                    <TableCell width={140} align="right"><b>Количество задач</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {monthReport.users_tasks
                                    .filter(t => reportGraphUser === '' ? true : reportGraphUser === t.bitrix_id)
                                    .map(t => {
                                        return (
                                            <MonthReportRow
                                                row={t}
                                                key={t.id}
                                                showTasks={showTasks}
                                                showReviewTasks={showReviewTasks}
                                                showSupportTasks={showSupportTasks}
                                                showRulesTasks={showRulesTasks}
                                                showDevelopmentTSTasks={showDevelopmentTSTasks}
                                                manuallyAchievements={manuallyAchievements}
                                                selectedSprint={selectedSprint}
                                                updateMonthReport={updateMonthReport}
                                            />
                                        )
                                    })}
                                <TableRow className='month-report__table__results'>
                                    <TableCell></TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="right"><b>{resultSpForTable.toFixed(2)}</b></TableCell>
                                    <TableCell align="right"><b>{showTasks ? monthReport.result_tasks_count : 0}</b></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <div className='month-report__graphic-block'>
                <LineChart
                    xAxis={[{
                        id: 'Years',
                        data: reportGraphYear !== 0
                            ? months
                            : monthsForLastYear,
                        scaleType: 'time',
                        valueFormatter: (date) => reportGraphYear !== 0
                            ? date.toLocaleString('default', { month: 'long' })
                            : date.toLocaleString('default', { month: 'long', year: "numeric" }),
                    }]}
                    yAxis={[
                        {
                            min: 0,
                            max: maxGraphYHeight,
                        },
                    ]}
                    series={[
                        {
                            label: "SP",
                            curve: graphSettings.smoothingLines,
                            data: showedSPTasks.map((sp, index) => sp !== 0 && showSP
                                ? Number(resultsGraph) === 0
                                    ? sp
                                    : Number((sp / graphForYear.weeks[index]).toFixed(2))
                                : null)

                        },
                        {
                            label: "Количество",
                            curve: graphSettings.smoothingLines,
                            data: graphForYear.tasks_count.map((t, index) =>
                                t !== 0 && showCount
                                    ? Number(resultsGraph) === 0
                                        ? t
                                        : Number((t / graphForYear.weeks[index]).toFixed(2))
                                    : null)
                        },
                    ]}
                    width={820}
                    height={400}
                >
                    {graphSettings.showLines
                        ? graphLines.map(line =>
                            <ChartsReferenceLine
                                y={line}
                                lineStyle={{ stroke: '#9f9f9f', strokeDasharray: '5 5' }}
                                key={line}
                            />
                        )
                        : null}
                </LineChart>
                <FormGroup className='month-report__graph-settings'>
                    <FormHelperText>Год</FormHelperText>
                    {
                        filteredSprints.years
                            ?
                            <Select
                                value={reportGraphYear}
                                onChange={(event) => setReportGraphYear(event.target.value)}
                            >
                                <MenuItem value={0} key={0}>Последний год</MenuItem>
                                {
                                    filteredSprints.years.map(year =>
                                        <MenuItem value={year} key={year}>{year}</MenuItem>
                                    )
                                }
                            </Select>
                            : null
                    }

                    <FormHelperText>Сотрудник</FormHelperText>
                    <Select
                        value={reportGraphUser}
                        onChange={(event) => setReportGraphUser(event.target.value)}
                        displayEmpty
                    >
                        <MenuItem value="" key={0}>Отдел</MenuItem>
                        {users.filter(user => user.show_in_report && user.active).map(user =>
                            <MenuItem value={user.bitrix_user_id} key={user.id}>{`${user.surname} ${user.name}`}</MenuItem>
                        )}
                    </Select>
                    <FormHelperText>Результат</FormHelperText>
                    <Select
                        value={resultsGraph}
                        onChange={(event) => setResultsGraph(event.target.value)}
                        displayEmpty
                    >
                        <MenuItem value="0" key={0}>Общий результат месяца</MenuItem>
                        <MenuItem value="1" key={1}>Средний недельный результат</MenuItem>
                    </Select>
                    <Button
                        variant="contained"
                        onClick={() => setShowGraphSettingsForm(true)}
                        style={{ marginTop: 10 }}
                    >
                        Настройки
                    </Button>
                    <FormControlLabel
                        control={<Checkbox checked={showSP} onChange={(event) => setShowSP(event.target.checked)} />}
                        label="Отображать SP" />
                    <FormControlLabel
                        control={<Checkbox checked={showCount} onChange={(event) => setShowCount(event.target.checked)} />}
                        label="Отображать количество" />
                    <div className='month-report__general-settings'>
                        <FormControlLabel
                            control={<Checkbox checked={showTasks} onChange={(event) => setShowTasks(event.target.checked)} />}
                            label="Учёт задач" />
                        <FormControlLabel
                            control={<Checkbox checked={showReviewTasks} onChange={(event) => setShowReviewTasks(event.target.checked)} />}
                            label="Учёт ревью" />
                        <FormControlLabel
                            control={<Checkbox checked={showSupportTasks} onChange={(event) => setShowSupportTasks(event.target.checked)} />}
                            label="Учёт задач поддержки" />
                        <FormControlLabel
                            control={<Checkbox checked={showRulesTasks} onChange={(event) => setShowRulesTasks(event.target.checked)} />}
                            label="Учёт задач на права" />
                        <FormControlLabel
                            control={<Checkbox checked={showDevelopmentTSTasks} onChange={(event) => setShowDevelopmentTSTasks(event.target.checked)} />}
                            label="Учёт задач на проработку ТЗ" />
                    </div>
                </FormGroup>
            </div>
            {showGraphSettingsForm
                ? <GraphSettingsForm
                    showGraphSettingsForm={setShowGraphSettingsForm}
                    settings={graphSettings}
                    setGraphSetting={setGraphSetting}
                    graphSettingsData={graphSettings}
                />
                : null}
        </div>

    );

}
