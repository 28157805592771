import React, { useState } from 'react';
import { useStore } from 'effector-react';
import {
    getTasksForSprintId,
    deleteTask,
    updateTaskMerge,
    setUserInTask,
    setViewedTask,
    setStatusTask
} from '../components/api';
import { setCards } from '../store/cards';
import {
    TableCell,
    TableRow,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText
} from '@mui/material';
import { Close, Check, Edit, AddTask, ContentCopy } from '@mui/icons-material';
import { $authUserData } from '../store/cards';
import { AccompliceCombobox } from './';
import '../styles/main.scss';
import { useClipboard } from 'use-clipboard-copy';

export const CardTableRow = (props) => {
    const getBackgroundColor = (card) => {
        return card.repository && card.repository.color
            ? card.repository.color + '33'
            : null
    }
    const {
        card,
        editTask,
        setRepositorySelectValue,
        setResponsibleSelectValue,
        setAccompliceSelectValue,
        setStatusValue,
        setLastSelectedTask,
        setSpSelectValue,
        setPrioritySelectValue,
        useIssue
    } = props;
    const authUserData = useStore($authUserData);
    const [showDialogDelete, setShowDialogDelete] = useState(false);
    const [background, setBackground] = useState(getBackgroundColor(card));

    const clipboard = useClipboard();

    const deleteTaskButtonClick = () => {
        setShowDialogDelete(true);
    };

    const updateTaskUser = (taskId) => {
        const userId = authUserData.id;
        setUserInTask(taskId, userId).then(() => {
            getTasksForSprintId(card.sprint.id).then(data => {
                setCards(data);
                setStatusValue(0);
                setLastSelectedTask(taskId);
            });
        });
    }

    const handleCloseDialog = (event, dialogResult) => {
        if (dialogResult) {
            deleteTask({ id: card.id }).then(() => {
                getTasksForSprintId(card.sprint.id).then(data => setCards(data));
            });
        }
        setShowDialogDelete(false);
    };

    const getPriorityColor = (priority) => {
        switch (priority) {
            case 10:
                return '#ff0000';
            case 9:
                return '#ff6100';
            case 8:
                return '#ff9602';
            case 7:
                return '#d4d41c';
            case 6:
                return '#87ff00';
            default:
                return '#008000';
        }
    };

    const getSPColor = (sp) => {
        switch (sp) {
            case 0:
            case 0.5:
                return '#545454';
            case 1:
                return '#0069ff';
            case 2:
                return '#00b219';
            case 3:
                return '#eaa30d';
            case 5:
                return '#db6d28';
            case 8:
                return '#ff3228';
            default:
                return '#db61a2';
        }
    };

    const getButtons = () => {
        if (authUserData) {
            if (authUserData.is_head_of_department) {
                return <div className="functional-buttons">
                    <IconButton onClick={deleteTaskButtonClick} style={{ padding: 0 }}>
                        <Close style={{ fontSize: 20, color: 'red' }} />
                    </IconButton>
                    <IconButton onClick={() => editTask(card)} style={{ padding: 0 }}>
                        <Edit style={{ fontSize: 20 }} />
                    </IconButton>
                </div>
            }
            else if (card.status.id === 1) {
                return <IconButton
                    onClick={() => updateTaskUser(card.id)}
                    style={{ padding: 0, color: 'green' }}
                    title="Взять задачу">
                    <AddTask style={{ fontSize: 20 }} />
                </IconButton>
            }
            else if (authUserData.bitrix_user_id === card.responsible_id.toString() && card.status.id === 2) {
                return <IconButton onClick={() => editTask(card)} style={{ padding: 0 }}>
                    <Edit style={{ fontSize: 20 }} />
                </IconButton>
            }
        }
        return null;
    }

    const updateViewedTask = () => {
        setViewedTask(card.id);
        card.viewed = true;
    }

    const changeStatusClick = (statusId) => {
        const changeStatusData = {
            TaskId: card.id,
            StatusId: statusId
        }
        setStatusTask(changeStatusData).then(() => {
            getTasksForSprintId(card.sprint.id).then(data => {
                setCards(data);
                setStatusValue(0);
            });
        });
    }

    const getChangeStatusButtons = () => {
        if (authUserData) {
            if (Number(authUserData.bitrix_user_id) === card.responsible_id
                && (card.status.id === 2
                    || card.status.id === 4
                    || card.status.id === 6)) {
                return <Button
                    className='table-row__button'
                    variant='contained'
                    onClick={() => changeStatusClick(3)}>
                    На ревью
                </Button>
            }
            if (Number(authUserData.bitrix_user_id) === card.accomplice_id
                && card.status.id === 3) {
                return <div style={{ display: "flex" }}>
                    <Button
                        className='table-row__button'
                        variant='contained'
                        onClick={() => changeStatusClick(5)}>
                        Ок
                    </Button>
                    <Button
                        className='table-row__button'
                        variant='contained'
                        onClick={() => changeStatusClick(4)}>
                        Исправить
                    </Button>
                </div>
            }

        }
        return null;
    }

    const copyClipboardClick = React.useCallback((value) => {
        clipboard.copy(value.toString());
    }, [clipboard.copy]);

    return (
        <TableRow
            key={card.task_id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            style={{ background: background }}
            onMouseEnter={() => setBackground(card.repository && card.repository.color ? card.repository.color + '4d' : '#0000000a')}
            onMouseLeave={() => setBackground(getBackgroundColor(card))}
        >
            <TableCell style={{ padding: "0px 0px 0px 10px" }} align="center">
                {getButtons()}
                {authUserData && authUserData.is_head_of_department
                    ?
                    null
                    : card.status.id === 1
                        ?
                        null
                        : null}

            </TableCell>
            <TableCell align="center">
                {card.repository
                    ? <p
                        onClick={() => setRepositorySelectValue(card.repository.id)}
                        className='table-row__use-filter'
                    >
                        {card.repository.name}
                    </p>
                    : getChangeStatusButtons()}
            </TableCell>
            <TableCell
                component="th"
                scope="card"
                align="center"
            >
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <a
                        href={`https://bitrix.borauto.ru/company/personal/user/1835/tasks/task/view/${card.task_id}/`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {card.task_id}
                    </a>
                    <IconButton
                        onClick={() => copyClipboardClick(card.task_id)}
                        style={{ padding: 0, marginLeft: 5 }}
                        title="Скопировать в буфер обмена"
                    >
                        <ContentCopy style={{ fontSize: 15 }} />
                    </IconButton>
                </div>

            </TableCell>
            <TableCell align="left" title={card.title} className='table-row__title table-row__viewed-block'>
                {!card.viewed && card.status.id === 2 && card.responsible_id === Number(authUserData.bitrix_user_id)
                    ? <div className='table-row__viewed-circle' onClick={() => updateViewedTask()}></div>
                    : null
                }
                {card.title}
            </TableCell>
            {useIssue
                ? <TableCell align="center">
                    {
                        card.repository
                            ? <a href={`https://github.com/borauto/${card.repository.name}/issues/${card.issue_id}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {card.issue_id > 0 ? card.issue_id : ''}
                            </a>
                            : <p>{card.issue_id > 0 ? card.issue_id : ''}</p>
                    }
                </TableCell>
                : null}

            <TableCell align="center">
                {
                    card.repository
                        ? <a
                            href={`https://github.com/borauto/${card.repository.name}/pull/${card.pr_id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {card.pr_id > 0 ? card.pr_id : ''}
                        </a>
                        : <p>{card.pr_id > 0 ? card.pr_id : ''}</p>
                }
            </TableCell>
            <TableCell>
                <p
                    onClick={() => setResponsibleSelectValue(card.responsible_id)}
                    className='table-row__use-filter'
                >
                    {card.responsible_name}
                </p>
            </TableCell>
            <TableCell style={{ padding: "2px 10px" }}>
                {
                    authUserData.is_head_of_department === true
                        ? <AccompliceCombobox task={card}></AccompliceCombobox>
                        : card.accomplice_name !== '0'
                            ? <p
                                onClick={() => setAccompliceSelectValue(card.accomplice_id)}
                                className='table-row__use-filter'
                            >
                                {card.accomplice_name}
                            </p>
                            : ''
                }
            </TableCell>
            <TableCell align="center">
                <b
                    className='table-row__use-filter'
                    style={{ color: getSPColor(card.sp_count) }}
                    onClick={() => setSpSelectValue(card.sp_count)}
                >
                    {card.sp_count}
                </b>
            </TableCell>
            <TableCell align="center">
                <b
                    className='table-row__use-filter'
                    style={{ color: getPriorityColor(card.priority), textShadow: "1px #FFFFFF" }}
                    onClick={() => setPrioritySelectValue(card.priority)}
                >
                    {card.priority}
                </b>
            </TableCell>
            <TableCell align="center">
                <p
                    style={{ background: card.status.color }}
                    className='card__status table-row__use-filter'
                    onClick={() => setStatusValue(card.status.id)}

                >
                    {card.status.name}
                </p>
            </TableCell>
            {
                card.is_merged
                    ? <TableCell align="center">
                        <IconButton style={{ padding: 0 }}>
                            <Check style={{ fontSize: 20, color: '#008000', fontWeight: 700 }} />
                        </IconButton>
                    </TableCell>
                    : card.status.id === 7 && authUserData.is_head_of_department && card.pr_id === 0
                        ? <TableCell align="center">
                            <input type="checkbox" onChange={(event) => updateTaskMerge(card.id, event.target.checked)} />
                        </TableCell>
                        : <TableCell align="center">
                            <b style={{ color: card.status_change_dif_color }}>
                                {card.status.id !== 7 ? card.status_change_dif : ""}
                            </b>
                        </TableCell>
            }
            <Dialog
                open={showDialogDelete}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Вы уверенны, что хотите удалить задачу?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(event) => handleCloseDialog(event, true)} color="primary">
                        Да
                    </Button>
                    <Button onClick={(event) => handleCloseDialog(event, false)} color="primary" autoFocus>
                        Нет
                    </Button>
                </DialogActions>
            </Dialog>
        </TableRow>
    )
}